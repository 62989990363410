
import { defineComponent } from 'vue';

import {
    IonPage,
    IonIcon,
    IonButton,
} from '@ionic/vue';


export default defineComponent({
    name: 'LoyaltyGuestConfirmation',

    components: {
        IonPage,
        IonIcon,
        IonButton,
    },
    data: ()=>({
        query: undefined as unknown as {[key: string]: string},
        mainScreenInterval: undefined as unknown as number,
    }),
    mounted() {
        this.query = location.search.slice(1).split('&').reduce((collection: {[key: string]: string}, item: string)=>{
            const [key, val] = item.split('=');
            collection[key] = decodeURIComponent(val);
            return collection;
        }, {});
    },
    methods: {
        goTo(target: string) {
            this.$router.push(target).catch((e) => {
                console.error(e);
            });
        },
        goBackToMainScreen() {
          this.mainScreenInterval = setInterval(() =>  this.$router.replace('/loyalty/guest') , 10000);
        },
    },
    
    computed: {
        success(): boolean {
            return this.query?.success === 'true'
        },
        isNewMember(): boolean {
            return this.success && this.query?.isNew === '1';
        },
        isNotNewMember(): boolean {
            return this.success && this.query?.isNew === '0';
        },
        heading(): string {
            if (this.isNewMember) {
                return 'WELCOME TO &REWARDS';
            } else if(this.isNotNewMember) {
                return 'LOOKS LIKE YOU ALREADY GOT THE 411';
            }
            return '';
        },
        message(): string {
            if (!this.success) {
                return this.query?.error;
            } else if (this.isNotNewMember) {
                return 'You’re already signed up for &rewards 🤑 Continue stacking Pie Points in shop, online or through the &pizza app!';
            } else if (this.isNewMember) {
                return 'Earning points is simple, easy and fun! To get you started we dropped you an offer for 10% off an entire order! You can stack/redeem Pie Points in shop, online or through the &pizza app 🤩';
            }
            return '';
        }, 
    },
    beforeMount() {
       this.goBackToMainScreen();
    },
    beforeUnmount() {
       if (this.mainScreenInterval) clearInterval(this.mainScreenInterval);
    }
});
